import * as React from 'react'

import IndexLayout from '../layouts'

import './index.scss'

const IndexPage = () => (
  <IndexLayout>
    <p>Thanks for your purchase!</p>
  </IndexLayout>
)

export default IndexPage
